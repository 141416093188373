import './bootstrap';
import '../css/app.css';
import '/node_modules/quill/dist/quill.snow.css'

import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import ReactGA from 'react-ga4';
import * as Sentry from "@sentry/react";

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

createInertiaApp({
    title: (title) => `${title}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx')),
    setup({ el, App, props }) {

        const currentUrl = window.location.pathname;

        if (!currentUrl.includes("ip-activity")) {
            ReactGA.initialize("G-QZL9231DQN");
        }        

        Sentry.init({
            dsn: "https://60c455897fbe9d446083b86f00ff3d12@o4508071799226368.ingest.us.sentry.io/4508071801126912",
            integrations: [
              Sentry.browserTracingIntegration(),
              Sentry.replayIntegration(),
            ],
            enabled: process.env.NODE_ENV === 'production',            
            // Tracing
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/letterstocrushes\.com\//],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });        

        const root = createRoot(el);

        root.render(<App {...props} />);
    },
    progress: {
        color: '#F59696',
    },
});
